export const AD_INTERVAL = 4;
export const ADMIN_PLAYER_ID = 2;
export const AGE_GROUPS = [
	{ value: 0, text: 'Any' },
	{ value: 1, text: 'Adult' },
	{ value: 2, text: '30 and up' },
	{ value: 3, text: '18 and up' },
	{ value: 4, text: '14 through 17' },
];
export const AGE_GROUP_ANY = 0;
export const AWS_S3_ACL_PUBLIC_READ = 'public-read';
export const AWS_S3_BUCKET = 'infinitehoops.com';
export const BROADSIDE_TOKEN_COOKIE = 'cookie';
export const BROADSIDE_TOKEN_HEADER = 'X-Broadside-Token';
export const CACHE_CONTROL_MAX_AGE = 'max-age = 31536000';
export const CACHE_OPTIONS = {
	expires: 60 * 60, // one hour in seconds
};
export const CONTENT_TYPE_IMAGE_JPEG = 'image/jpeg';
export const DATE_FORMAT = 'M/D/YYYY';
export const DATE_FORMAT_INPUT = 'YYYY-MM-DD';
export const DATE_REFRESH_INTERVAL = 1000 * 60; // one minute
export const DATE_TIME_FORMAT_INPUT = 'YYYY-MM-DDTHH:mm';
export const DEFAULT_ANIMATION_DURATION = 200;
export const DEFAULT_COUNT = 10;
export const DEFAULT_MARGIN = 16;
export const DELETE_CONTENT_DAYS = 4;
export const DOUBLE_DEFAULT_MARGIN = 32;
export const GAME_DAY = {
	SUNDAY: 1,
	MONDAY: 2,
	TUESDAY: 4,
	WEDNESDAY: 8,
	THURSDAY: 16,
	FRIDAY: 32,
	SATURDAY: 64,
};
export const GAME_DURATION = [
	{ value: 30, text: '0.5 hours' },
	{ value: 60, text: '1 hour' },
	{ value: 90, text: '1.5 hours' },
	{ value: 120, text: '2 hours' },
	{ value: 150, text: '2.5 hours' },
	{ value: 180, text: '3 hours' },
	{ value: 210, text: '3.5 hours' },
	{ value: 240, text: '4 hours' },
];
export const GAME_DURATION_ONE_HOUR = 60;
export const GAME_FREQUENCY = [
	{ value: 0, text: 'Only Once' },
	{ value: 1, text: 'Every Week' },
];
export const GAME_FREQUENCY_ONLY_ONCE = 0;
export const GAME_FREQUENCY_EVERY_WEEK = 1;
export const GAME_RESTRICTION_DATA_TEN_PLAYERS = 10;
export const GAME_RESTRICTION_MODE = [
	{ value: 0, text: 'No Player Limit' },
	{ value: 1, text: 'Only Group Members' },
	{ value: 2, text: 'By Count' },
];
export const GAME_RESTRICTION_MODE_NO_PLAYER_LIMIT = 0;
export const GAME_RESTRICTION_MODE_BY_COUNT = 2;
export const GAME_STATUS = [
	{ value: 0, text: 'On' },
	{ value: 1, text: 'Canceled' },
];
export const GAME_STATUS_ON = 0;
export const GAME_STATUS_CANCELED = 1;
export const GEO_SEARCH_RADIUS = 0.72; // ~ 50 miles
export const IMAGE_HEADER_WIDTH = 1120; // double INNER_CONTAINER_WIDTH
export const IMAGE_SQUARE_WIDTH = 112;
export const INNER_CONTAINER_WIDTH = 560;
export const JAN_PLAYER_ID = 1088;
export const JWT_COOKIE_NAME = 'token';
export const JWT_COOKIE_EXPIRY = 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds
export const JWT_EXPIRY_SECONDS = '100y';
export const LOCAL_STORAGE_KEYS = {
	MAP_CENTER: 'MAP_CENTER',
	MAP_ZOOM: 'MAP_ZOOM',
};
export const MAIN_MENU_WIDTH = 232;
export const NICE_DATE_FORMAT = 'ddd, MMM D \\at h:mm a';
export const JOINED_DATE_FORMAT = 'MMM YYYY';
export const NBA_FULL_SCHEDULE_API =
	'https://data.nba.com/data/10s/v2015/json/mobile_teams/nba/2023/league/00_full_schedule.json';
export const NBA_SCHEDULE_API = 'https://data.nba.net/prod/v2/2022/schedule.json'; // no longer working
export const NBA_STANDINGS_API =
	'https://stats.nba.com/stats/playoffpicture?SeasonID=22023&LeagueID=00';
export const NODE_ENV_DEV = 'Development';
export const NOTIFICATION_TYPE = {
	JOINED_SITE: 0,
	JOINED_TEAM: 1,
	LEFT_TEAM: 2,
	CREATED_GAME: 3,
	CREATED_RECURRING_GAME: 4,
	CREATED_TEAM: 5,
	CREATED_TEAM_MESSAGE: 6,
	SET_PLAYER_GAME_STATUS: 7,
	UPDATED_GAME: 8,
	UPDATED_RECURRING_GAME: 9,
	UPDATED_TEAM: 10,
};
export const PLAYER_GAME_STATUS = {
	OUT: 0,
	IN: 1,
	UNDECIDED: 2,
	SLACKER: 3,
};
export const RESET_PASSWORD_CODE_EXPIRY_SECONDS = 60 * 60; // minutes
export const RESULT = {
	CHANGE_PASSWORD_NEW_PASSWORD_EMPTY: 'CHANGE_PASSWORD_NEW_PASSWORD_EMPTY',
	CHANGE_PASSWORD_OLD_PASSWORD_EMPTY: 'CHANGE_PASSWORD_OLD_PASSWORD_EMPTY',
	CHANGE_PASSWORD_OLD_PASSWORD_INVALID: 'CHANGE_PASSWORD_OLD_PASSWORD_INVALID',
	CHANGE_PASSWORD_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH:
		'CHANGE_PASSWORD_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH',
	CHANGE_PASSWORD_PLAYER_NOT_FOUND: 'CHANGE_PASSWORD_PLAYER_NOT_FOUND',
	DEVICE_NOT_FOUND: 'DEVICE_NOT_FOUND',
	DEVICE_TOKEN_EMPTY: 'DEVICE_TOKEN_EMPTY',
	IMAGE_FILE_MISSING: 'IMAGE_FILE_MISSING',
	INVALID_LATITUDE_LONGITUDE_VALUE: 'INVALID_LATITUDE_LONGITUDE_VALUE',
	MISSING_LATITUDE_LONGITUDE_PAIR: 'MISSING_LATITUDE_LONGITUDE_PAIR',
	RESET_PASSWORD_CODE_EXPIRED: 'RESET_PASSWORD_CODE_EXPIRED',
	RESET_PASSWORD_CODE_INVALID: 'RESET_PASSWORD_CODE_INVALID',
	RESET_PASSWORD_CODE_VALID: 'RESET_PASSWORD_CODE_VALID',
	RESET_PASSWORD_CODE_NOT_FOUND: 'RESET_PASSWORD_CODE_NOT_FOUND',
	RESET_PASSWORD_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH:
		'RESET_PASSWORD_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH',
	RESET_PASSWORD_PASSWORD_EMPTY: 'RESET_PASSWORD_PASSWORD_EMPTY',
	RESET_PASSWORD_PLAYER_NOT_FOUND: 'RESET_PASSWORD_PLAYER_NOT_FOUND',
	SIGN_IN_EMAIL_EMPTY: 'SIGN_IN_EMAIL_EMPTY',
	SIGN_IN_FAILED: 'SIGN_IN_FAILED',
	SIGN_IN_PASSWORD_EMPTY: 'SIGN_IN_PASSWORD_EMPTY',
	SIGN_IN_PLAYER_NOT_FOUND: 'SIGN_IN_PLAYER_NOT_FOUND',
	SIGN_UP_EMAIL_EMPTY: 'SIGN_UP_EMAIL_EMPTY',
	SIGN_UP_NAME_EMPTY: 'SIGN_UP_NAME_EMPTY',
	SIGN_UP_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH:
		'SIGN_UP_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH',
	SIGN_UP_PASSWORD_EMPTY: 'SIGN_UP_PASSWORD_EMPTY',
	SIGN_UP_PLAYER_ALREADY_EXISTS: 'SIGN_UP_PLAYER_ALREADY_EXISTS',
	SUCCEEDED: 'SUCCEEDED',
};
export const SAN_FRANSISCO_COORDINATES = [37.78684212907965, -122.41022745605213]; // lat/long
export const SKILL_LEVEL = [
	{ value: 0, text: 'Recreational' },
	{ value: 1, text: 'Intermediate' },
	{ value: 2, text: 'Competitive' },
];
export const SKILL_LEVEL_RECREATIONAL = 0;
export const SYSTEM_ACCOUNT = {
	id: 1,
	name: 'System',
};
export const WORK_QUEUE = 'WORK_QUEUE';
export const UPCOMING_GAMES_COUNT = 20;
